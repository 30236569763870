const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const codeGeneration = require('../codeGeneration');

let setupCode = '';
let loopCode = '';
let defineCode = '';
let bodyCode = '';
let codeContext = 'setup';
let definedMotors = new Set();
let definedFunctions = new Set();

class Scratch3ArduinoRoboticCarBlocks {
    constructor(runtime) {
        this.runtime = runtime;
        codeContext = localStorage.getItem('codeContext');
    }

    getInfo() {
        return {
            id: 'arduinoroboticcar',
            color1: '#97ad17',
            name: formatMessage({
                id: 'arduinoroboticcar.categoryName',
                default: 'Robotic Car',
                description: 'Arduino Robotic Car extension category'
            }),
            blocks: [
                {
                    opcode: 'defineMotor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinoroboticcar.defineMotor',
                        default: 'define [motor] with pins [pin1] and [pin2]',
                        description: 'Define a motor with two pins and configure them as input or output'
                    }),
                    arguments: {
                        motor: {
                            type: ArgumentType.STRING,
                            menu: 'motorMenu',
                            defaultValue: 'motor1'
                        },
                        pin1: {
                            type: ArgumentType.STRING,
                            menu: 'digitalPortMenu',
                            defaultValue: '4'
                        },
                        pin2: {
                            type: ArgumentType.STRING,
                            menu: 'digitalPortMenu',
                            defaultValue: '5'
                        }
                    }
                },
                {
                    opcode: 'moveDirection',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinoroboticcar.moveDirection',
                        default: 'move towards [direction] direction for [time] milliseconds',
                        description: 'Move the robotic car in the specified direction for the given time'
                    }),
                    arguments: {
                        direction: {
                            type: ArgumentType.STRING,
                            menu: 'directionMenu',
                            defaultValue: 'forward'
                        },
                        time: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1000
                        }
                    }
                },
                {
                    opcode: 'turnDirection',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinoroboticcar.turnDirection',
                        default: 'turn towards [direction] direction for [time] milliseconds',
                        description: 'Turn the robotic car in the specified direction for the given time'
                    }),
                    arguments: {
                        direction: {
                            type: ArgumentType.STRING,
                            menu: 'turnDirectionMenu',
                            defaultValue: 'left'
                        },
                        time: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1000
                        }
                    }
                },
                {
                    opcode: 'stopMovement',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinoroboticcar.stopMovement',
                        default: 'Stop moving for [time] milliseconds',
                        description: 'Stop all motors for the specified time'
                    }),
                    arguments: {
                        time: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1000
                        }
                    }
                },
                {
                    opcode: 'moveTowardsDirection',
                    blockType: BlockType.COMMAND,
                    text: 'Move towards [direction] direction',
                    arguments: {
                        direction: {
                            type: ArgumentType.STRING,
                            menu: 'directionMenu',
                            defaultValue: 'forward'
                        }
                    }
                },
                {
                    opcode: 'turnTowardsDirection',
                    blockType: BlockType.COMMAND,
                    text: 'Turn towards [direction] direction',
                    arguments: {
                        direction: {
                            type: ArgumentType.STRING,
                            menu: 'turnDirectionMenu',
                            defaultValue: 'left'
                        }
                    }
                },
                {
                    opcode: 'stopMoving',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinoroboticcar.stopMoving',
                        default: 'stop moving',
                        description: 'Stop all motors'
                    })
                }
            ],
            menus: {
                digitalPortMenu: {
                    items: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
                },
                motorMenu: {
                    items: ['motor1', 'motor2', 'motor3', 'motor4']
                },
                directionMenu: {
                    items: ['forward', 'backward']
                },
                turnDirectionMenu: {
                    items: ['left', 'right']
                }
            }
        };
    }
    accumulateCode() {
        codeGeneration.accumulateCode(defineCode, bodyCode, setupCode, loopCode);
    }
    resetCode() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
    }
    full_reset() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
        definedMotors.clear();
        definedFunctions.clear();
    }
    defineMotor(args) {
        const motor = args.motor;
        const pin1 = args.pin1;
        const pin2 = args.pin2;

        if (definedMotors.has(motor)) {
            return;
        }

        let motorPrefix;
        switch (motor) {
            case 'motor1':
                motorPrefix = 'M1';
                break;
            case 'motor2':
                motorPrefix = 'M2';
                break;
            case 'motor3':
                motorPrefix = 'M3';
                break;
            case 'motor4':
                motorPrefix = 'M4';
                break;
            default:
                console.error('Invalid motor selection.');
                return;
        }
        defineCode += `#define ${motorPrefix}A ${pin1}\n#define ${motorPrefix}B ${pin2}\n`;
        setupCode += `pinMode(${motorPrefix}A, OUTPUT);\n`;
        setupCode += `pinMode(${motorPrefix}B, OUTPUT);\n`;
        definedMotors.add(motor);
        this.accumulateCode();
        this.resetCode();
    }
    async moveDirection(args) {
        const direction = args.direction;
        const time = args.time;
        let codeToAdd = '';

        if (direction === 'forward') {
            if (!definedFunctions.has('forward_')) {
                bodyCode += `
                void forward_(int time_) {
                    ${definedMotors.has('motor1') ? 'digitalWrite(M1A, HIGH); digitalWrite(M1B, LOW);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(M2A, HIGH); digitalWrite(M2B, LOW);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(M3A, HIGH); digitalWrite(M3B, LOW);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(M4A, HIGH); digitalWrite(M4B, LOW);' : ''}
                    delay(time_);
                }\n`;
                definedFunctions.add('forward_');
            }
            codeToAdd = `forward_(${time});\n`;

            if (typeof window.moveForward === 'function') {
                for (let i = 0; i < time / 100; i++) {
                    window.moveForward();
                    await new Promise(resolve => setTimeout(resolve, 100)); // Add delay of 100ms
                }
                window.stopForward(); // Stop moving after the loop completes
            } else {
                console.error('moveForward function is not available');
            }
        } else if (direction === 'backward') {
            if (!definedFunctions.has('backward_')) {
                bodyCode += `
                void backward_(int time_) {
                    ${definedMotors.has('motor1') ? 'digitalWrite(M1A, LOW); digitalWrite(M1B, HIGH);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(M2A, LOW); digitalWrite(M2B, HIGH);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(M3A, LOW); digitalWrite(M3B, HIGH);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(M4A, LOW); digitalWrite(M4B, HIGH);' : ''}
                    delay(time_);
                }\n`;
                definedFunctions.add('backward_');
            }
            codeToAdd = `backward_(${time});\n`;

            if (typeof window.moveBackward === 'function') {
                for (let i = 0; i < time / 100; i++) {
                    window.moveBackward();
                    await new Promise(resolve => setTimeout(resolve, 100)); // Add delay of 100ms
                }
                window.stopBackward(); // Stop moving after the loop completes
            } else {
                console.error('moveBackward function is not available');
            }
        }

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    async turnDirection(args) {
        const direction = args.direction;
        const time = args.time;
        let codeToAdd = '';

        if (direction === 'left') {
            bodyCode += `
            void left_(int time_) {
                ${definedMotors.has('motor1') ? 'digitalWrite(M1A, HIGH); digitalWrite(M1B, LOW);' : ''}
                ${definedMotors.has('motor2') ? 'digitalWrite(M2A, LOW); digitalWrite(M2B, HIGH);' : ''}
                ${definedMotors.has('motor3') ? 'digitalWrite(M3A, HIGH); digitalWrite(M3B, LOW);' : ''}
                ${definedMotors.has('motor4') ? 'digitalWrite(M4A, LOW); digitalWrite(M4B, HIGH);' : ''}
                delay(time_);
            }\n`;

            codeToAdd = `left_(${time});\n`;

            if (typeof window.turnLeft === 'function') {
                for (let i = 0; i < time / 100; i++) {
                    window.turnLeft();
                    await new Promise(resolve => setTimeout(resolve, 100)); // Add delay of 100ms
                }
                window.stopLeft(); // Stop turning after the loop completes
            } else {
                console.error('turnLeft function is not available');
            }
        } else if (direction === 'right') {
            bodyCode += `
            void right_(int time_) {
                ${definedMotors.has('motor1') ? 'digitalWrite(M1A, LOW); digitalWrite(M1B, HIGH);' : ''}
                ${definedMotors.has('motor2') ? 'digitalWrite(M2A, HIGH); digitalWrite(M2B, LOW);' : ''}
                ${definedMotors.has('motor3') ? 'digitalWrite(M3A, LOW); digitalWrite(M3B, HIGH);' : ''}
                ${definedMotors.has('motor4') ? 'digitalWrite(M4A, HIGH); digitalWrite(M4B, LOW);' : ''}
                delay(time_);
            }\n`;
            codeToAdd = `right_(${time});\n`;

            if (typeof window.turnRight === 'function') {
                for (let i = 0; i < time / 100; i++) {
                    window.turnRight();
                    await new Promise(resolve => setTimeout(resolve, 100)); // Add delay of 100ms
                }
                window.stopRight(); // Stop turning after the loop completes
            } else {
                console.error('turnRight function is not available');
            }
        }

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    async stopMovement(args) {
        const time = args.time;
        if (!definedFunctions.has('stops_')) {
            bodyCode += `
            void stops_(int time_) {
                ${definedMotors.has('motor1') ? 'digitalWrite(M1A, LOW); digitalWrite(M1B, LOW);' : ''}
                ${definedMotors.has('motor2') ? 'digitalWrite(M2A, LOW); digitalWrite(M2B, LOW);' : ''}
                ${definedMotors.has('motor3') ? 'digitalWrite(M3A, LOW); digitalWrite(M3B, LOW);' : ''}
                ${definedMotors.has('motor4') ? 'digitalWrite(M4A, LOW); digitalWrite(M4B, LOW);' : ''}
                delay(time_);
            }\n`;
            definedFunctions.add('stops_');
        }
        if (typeof window.stopForward === 'function' || typeof window.stopBackward === 'function' || typeof window.stopRight === 'function' || typeof window.stopLeft === 'function') {
            for (let i = 0; i < time / 100; i++) {
                window.stopForward();
                window.stopBackward();
                window.stopRight();
                window.stopLeft();
                await new Promise(resolve => setTimeout(resolve, 100)); // Add delay of 100ms
            }
            // Stop moving after the loop completes
        } else {
            console.error('moveBackward function is not available');
        }
        const codeToAdd = `stops_(${time});\n`;
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    moveTowardsDirection(args) {
        const direction = args.direction;
        let codeToAdd = '';
        if (direction === 'forward') {
            if (!definedFunctions.has('forward')) {
                bodyCode += `
                void forward() {
                    ${definedMotors.has('motor1') ? 'digitalWrite(M1A, HIGH); digitalWrite(M1B, LOW);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(M2A, HIGH); digitalWrite(M2B, LOW);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(M3A, HIGH); digitalWrite(M3B, LOW);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(M4A, HIGH); digitalWrite(M4B, LOW);' : ''}
                }\n`;
                definedFunctions.add('forward');
            }
            if (typeof window.moveForward === 'function') {
                window.moveForward();
            } else {
                console.error('moveForward function is not available');
            }
            codeToAdd = `forward();\n`;
        } else if (direction === 'backward') {
            if (!definedFunctions.has('backward')) {
                bodyCode += `
                void backward() {
                    ${definedMotors.has('motor1') ? 'digitalWrite(M1A, LOW); digitalWrite(M1B, HIGH);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(M2A, LOW); digitalWrite(M2B, HIGH);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(M3A, LOW); digitalWrite(M3B, HIGH);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(M4A, LOW); digitalWrite(M4B, HIGH);' : ''}
                }\n`;
                definedFunctions.add('backward');
            }
            codeToAdd = `backward();\n`;
            if (typeof window.moveBackward === 'function') {
                window.moveBackward();
            } else {
                console.error('moveBackward function is not available');
            }
        }

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    turnTowardsDirection(args) {
        const direction = args.direction;
        let codeToAdd = '';

        if (direction === 'left') {
            if (!definedFunctions.has('left')) {
                bodyCode += `
                void left() {
                    ${definedMotors.has('motor1') ? 'digitalWrite(M1A, HIGH); digitalWrite(M1B, LOW);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(M2A, HIGH); digitalWrite(M2B, LOW);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(M3A, LOW); digitalWrite(M3B, HIGH);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(M4A, LOW); digitalWrite(M4B, HIGH);' : ''}
                }\n`;
                definedFunctions.add('left');
            }
            if (typeof window.turnLeft === 'function') {
                window.turnLeft();
            } else {
                console.error('turnLeft function is not available');
            }
            codeToAdd = `left();\n`;
        } else if (direction === 'right') {
            if (!definedFunctions.has('right')) {
                bodyCode += `
                void right() {
                    ${definedMotors.has('motor1') ? 'digitalWrite(M1A, LOW); digitalWrite(M1B, HIGH);' : ''}
                    ${definedMotors.has('motor2') ? 'digitalWrite(M2A, LOW); digitalWrite(M2B, HIGH);' : ''}
                    ${definedMotors.has('motor3') ? 'digitalWrite(M3A, HIGH); digitalWrite(M3B, LOW);' : ''}
                    ${definedMotors.has('motor4') ? 'digitalWrite(M4A, HIGH); digitalWrite(M4B, LOW);' : ''}
                }\n`;
                definedFunctions.add('right');
            }
            if (typeof window.turnRight === 'function') {
                window.turnRight();
            } else {
                console.error('turnLeft function is not available');
            }
            codeToAdd = `right();\n`;
        }
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
    async stopMoving() {
        if (!definedFunctions.has('stops')) {
            bodyCode += `
            void stops() {
                ${definedMotors.has('motor1') ? 'digitalWrite(M1A, LOW); digitalWrite(M1B, LOW);' : ''}
                ${definedMotors.has('motor2') ? 'digitalWrite(M2A, LOW); digitalWrite(M2B, LOW);' : ''}
                ${definedMotors.has('motor3') ? 'digitalWrite(M3A, LOW); digitalWrite(M3B, LOW);' : ''}
                ${definedMotors.has('motor4') ? 'digitalWrite(M4A, LOW); digitalWrite(M4B, LOW);' : ''}
            }\n`;
            definedFunctions.add('stops');
        }
        if (typeof window.stopForward === 'function' || typeof window.stopBackward === 'function' || typeof window.stopRight === 'function' || typeof window.stopLeft === 'function') {
            window.stopForward();
            window.stopBackward();
            window.stopRight();
            window.stopLeft();
        } else {
            console.error('moveForward function is not available');
        }
        const codeToAdd = `stops();\n`;
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }
        this.accumulateCode();
        this.resetCode();
    }
}

module.exports = Scratch3ArduinoRoboticCarBlocks;
