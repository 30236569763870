// src/utils/authService.js

import axios from 'axios';

export const refreshAccessToken = async () => {
  try {
    const response = await axios.post('https://api.stemverse.app/auth/refresh', {}, { withCredentials: true }); //http://localhost:3500/auth/refresh
    return response.data.accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;  // Trigger logout in `apiClient.js` if refresh fails
  }
};

export const logoutUser = async () => {
    try {
      await axios.post('http://localhost:3500/auth/logout', {}, { withCredentials: true }); //https://api.stemverse.app/auth/logout
      localStorage.removeItem('accessToken');  // Clear access token from local storage
    //   window.location.href = '/login';         // Redirect to login page
    } catch (error) {
      console.error('Error during logout:', error);
      // Still redirect to login page even if the server call fails
      localStorage.removeItem('accessToken');
    //   window.location.href = '/login';
    }
  };
