import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import extensionTags from '../lib/libraries/extensionLibrary-tag.js';
import extensionLibraryContent from '../lib/libraries/extensions/index.jsx';
import tutorialTags from '../lib/libraries/tutorial-tags';

import LibraryComponent from '../components/library/library_extension.jsx';
import extensionIcon from '../components/action-menu/icon--sprite.svg';
import PythonTab from '../components/gui/code-editor';

import { activateTab, PYTHON_TAB_INDEX } from '../reducers/editor-tab';

const messages = defineMessages({
  extensionTitle: {
    defaultMessage: 'Choose an Extension',
    description: 'Heading for the extension library',
    id: 'gui.extensionLibrary.chooseAnExtension'
  },
  extensionUrl: {
    defaultMessage: 'Enter the URL of the extension',
    description: 'Prompt for unoffical extension url',
    id: 'gui.extensionLibrary.extensionUrl'
  },
  tipsLibraryTitle: {
    defaultMessage: 'Choose a Tutorial',
    description: 'Heading for the help/tutorials library',
    id: 'gui.tipsLibrary.tutorials'
  }
});

class ExtensionLibrary extends React.PureComponent {
  constructor(props) {
    super(props);

    bindAll(this, [
      'handleItemSelect',
      'handleSearchInputChange',
      'handleInstallClick',
      'handleDetailsClick',
      'handleDeleteClick',
      'handleFilterClick'
    ]);

    this.state = {
      searchQuery: '', // State for the search query
      filteredExtensions: extensionLibraryContent, // Initially set to all extensions
      selectedExtension: null // State to track selected extension
    };
  }

  handleItemSelect(item) {
    const id = item.extensionId;
    let url = item.extensionURL ? item.extensionURL : id;
    if (id === 'python') {
      // this.props.onActivatePythonTab();
      // this.setState({ selectedExtension: 'python' });
      this.props.vm.extensionManager.loadExtensionURL('python_imports');
      this.props.vm.extensionManager.loadExtensionURL('python_controls');
    } else if (!item.disabled && !id) {
      url = prompt(this.props.intl.formatMessage(messages.extensionUrl));
    }
    if (id && !item.disabled) {
      if (this.props.vm.extensionManager.isExtensionLoaded(url)) {
        this.props.onCategorySelected(id);
      } else {
        this.props.vm.extensionManager.loadExtensionURL(url).then(() => {
          this.props.onCategorySelected(id);
          // Initialize shared state if it's the camera extension
          if (id === 'HumanBody') {
            if (!window.sharedCameraState) {
              window.sharedCameraState = { isCameraOpen: false };
            }
          }
        });
      }
    }
  }

  handleSearchInputChange(event) {
    const searchQuery = event.target.value.toLowerCase();
    this.filterExtensions(searchQuery);
  }

  handleFilterClick(filter) {
    this.filterExtensions(filter.toLowerCase());
  }

  filterExtensions(query) {
    const filteredExtensions = extensionLibraryContent.filter(extension =>
      extension.name.toLowerCase().includes(query)
    );
    this.setState({
      searchQuery: query,
      filteredExtensions
    });
  }

  handleInstallClick(extensionId) {
    console.log(`Install clicked for extension ${extensionId}`);
  }

  handleDetailsClick(extensionId) {
    console.log(`Details clicked for extension ${extensionId}`);
  }

  handleDeleteClick(extensionId) {
    console.log(`Delete clicked for extension ${extensionId}`);
  }

  render() {
    const { searchQuery, filteredExtensions, selectedExtension } = this.state;
    const extensionLibraryThumbnailData = filteredExtensions.map(extension => ({
      rawURL: extension.iconURL || extensionIcon,
      ...extension
    }));

    // Check if the selected extension is 'python' and render PythonTab if it is
    if (selectedExtension === 'python') {
      return <PythonTab />;
    }

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <input
            type="text"
            placeholder="Search extensions..."
            value={searchQuery}
            onChange={this.handleSearchInputChange}
            style={{ marginRight: '10px' }}
          />
          <button style={{ marginRight: '10px' }} onClick={() => alert('Search clicked')}>
            Search
          </button>
          <button style={{ marginRight: '10px' }} onClick={() => alert('Button 1 clicked')}>
            Button 1
          </button>
          <button onClick={() => alert('Button 2 clicked')}>
            Button 2
          </button>
        </div>
        <LibraryComponent
          data={extensionLibraryThumbnailData}
          filterable={false}
          id="extensionLibrary"
          title={this.props.intl.formatMessage(messages.extensionTitle)}
          visible={this.props.visible}
          onItemSelected={this.handleItemSelect}
          onRequestClose={this.props.onRequestClose}
          renderAdditionalControls={extension => (
            <div>
              {/* Buttons for each extension */}
              <button onClick={() => this.handleInstallClick(extension.extensionId)}>
                Install
              </button>
              <button onClick={() => this.handleDetailsClick(extension.extensionId)}>
                Details
              </button>
              <button onClick={() => this.handleDeleteClick(extension.extensionId)}>
                Delete
              </button>
            </div>
          )}
        />
      </div>
    );
  }
}

ExtensionLibrary.propTypes = {
  intl: intlShape.isRequired,
  onCategorySelected: PropTypes.func,
  onRequestClose: PropTypes.func,
  visible: PropTypes.bool,
  vm: PropTypes.instanceOf(VM).isRequired,
  onActivatePythonTab: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onActivatePythonTab: () => dispatch(activateTab(PYTHON_TAB_INDEX)),
});

export default injectIntl(connect(null, mapDispatchToProps)(ExtensionLibrary));
