const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const codeGeneration = require('../codeGeneration');

let setupCode = '';
let loopCode = '';
let defineCode = '';
let bodycode = '';
let codeContext = 'setup';
const definedFunctions = new Set();
const definedServos = new Set(); // Keep track of defined servos
class Scratch3RoboticArmBlocks {
  constructor(runtime) {
    this.runtime = runtime;
    codeContext = localStorage.getItem('codeContext');
  }
  getInfo() {
    return {
      id: 'roboticarm',
      color1: '#0b0ed9',
      name: formatMessage({
        id: 'roboticarm.categoryName',
        default: 'Robotic Arm',
        description: 'Extension to control a robotic arm based on Arduino.'
      }),
      blocks: [
        {
          opcode: 'calibrate',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.calibrate',
            default: 'Calibrate servos',
            description: 'Move all servos to their calibrated positions.'
          }),
          func: 'calibrate'
        },
        {
          opcode: 'setInitialPosition',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.setInitialPosition',
            default: 'Set initial positions',
            description: 'Set the initial positions for all servos.'
          }),
          func: 'setInitialPosition'
        },
        {
          opcode: 'setServo',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.setServo',
            default: 'Set the [base] servo to pin number [pinNo]',
            description: 'Attach a servo to the specified pin based on the base selected.'
          }),
          arguments: {
            base: {
              type: ArgumentType.STRING,
              menu: 'baseMenu',
              defaultValue: 'base'
            },
            pinNo: {
              type: ArgumentType.STRING,
              menu: 'pinMenu',
              defaultValue: '2'
            }
          },
          func: 'setServo'
        },
        {
          opcode: 'moveBase',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.moveBase',
            default: 'Move base to [angle] degrees with speed [speed]',
            description: 'Move the base servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 90
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveBase'
        },
        {
          opcode: 'moveShoulder',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.moveShoulder',
            default: 'Move shoulder to [angle] degrees with speed [speed]',
            description: 'Move the shoulder servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 90
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveShoulder'
        },
        {
          opcode: 'moveElbow',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.moveElbow',
            default: 'Move elbow to [angle] degrees with speed [speed]',
            description: 'Move the elbow servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 90
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveElbow'
        },
        {
          opcode: 'moveWrist1',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.moveWrist1',
            default: 'Move wrist1 to [angle] degrees with speed [speed]',
            description: 'Move the wrist1 servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 90
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveWrist1'
        },
        {
          opcode: 'moveWrist2',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.moveWrist2',
            default: 'Move wrist2 to [angle] degrees with speed [speed]',
            description: 'Move the wrist2 servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 90
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveWrist2'
        },
        {
          opcode: 'moveGrip',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.moveGrip',
            default: 'Move grip to [angle] degrees with speed [speed]',
            description: 'Move the grip servo to the specified angle with the given speed.'
          }),
          arguments: {
            angle: {
              type: "angle",
              defaultValue: 110
            },
            speed: {
              type: ArgumentType.NUMBER,
              defaultValue: 15
            }
          },
          func: 'moveGrip'
        },
        {
          opcode: 'homePosition',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.homePosition',
            default: 'Move to home position',
            description: 'Move all servos to their home positions.'
          }),
          func: 'homePosition'
        },
        {
          opcode: 'finalizeCode',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticarm.finalizeCode',
            default: 'Reset code',
            description: 'Finalizes and logs the accumulated code for the Arduino sketch.'
          }),
          func: 'finalizeCode'
        },
      ],
      menus: {
        headerMenu: {
          items: ['Wire.h', 'Adafruit_PWMServoDriver.h', 'Servo.h']
        },
        baseMenu: {
          items: ['base', 'shoulder', 'elbow', 'wrist1', 'wrist2', 'grip']
        },
        pinMenu: {
          items: ['2', '3', '4', '5', '6', '7']
        },
        contextMenu: {
          items: ['setup', 'loop']
        }
      }
    };
  }
  resetCode() {
    // Reset all accumulated code and status
    setupCode = '';
    loopCode = '';
    defineCode = '';
    bodycode = '';
  }
  full_reset() {
    setupCode = '';
    loopCode = '';
    defineCode = '';
    bodycode = '';
    definedServos.clear(); // Clear the set of defined servos
    definedFunctions.clear();
    console.log('Arm Code has been reset.');
  }

  accumulateCode() {
    codeGeneration.accumulateCode(defineCode, bodycode, setupCode, loopCode);
  }
  setServo(args) {
    const base = args.base;
    const pinNo = args.pinNo;
    let servoVar;

    // Determine the correct servo variable based on the base
    switch (base) {
      case 'base':
        servoVar = 'servo_b';
        break;
      case 'shoulder':
        servoVar = 'servo_s';
        break;
      case 'elbow':
        servoVar = 'servo_e';
        break;
      case 'wrist1':
        servoVar = 'servo_w1';
        break;
      case 'wrist2':
        servoVar = 'servo_w2';
        break;
      case 'grip':
        servoVar = 'servo_grp';
        break;
      default:
        console.error('Invalid base');
        return;
    }
    // Add the servo variable initialization to the setup code if not already present
    if (!definedServos.has(servoVar)) {
      defineCode += `Servo ${servoVar};\n`;
      definedServos.add(servoVar);
    }

    // Add the servo attachment to the setup code
    setupCode += `${servoVar}.attach(${pinNo});\n`;
    this.accumulateCode();
    this.resetCode();
  }
  moveBase(args) {
    const servoVar = 'servo_b';
    const MIN_ANGLE = 0;    // Define your minimum angle
    const MAX_ANGLE = 180;
    const angle = args.angle;
    const speed = args.speed;
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('arm', angle);
    }
    // Generate the moveServo_b function
    if (!definedFunctions.has('moveServo_b')) {
      bodycode += `
          void moveServo_b(int endAngle, int delayTime) {
            if (servo_b_pos < endAngle) {
              for (int angle = servo_b_pos; angle <= endAngle; angle++) {
                servo_b.write(angle);
                delay(delayTime);
              }
            } else {
              for (int angle = servo_b_pos; angle >= endAngle; angle--) {
                servo_b.write(angle);
                delay(delayTime);
              }
            }
            // Update the current position
            servo_b_pos = endAngle;
          }
        `;
      definedFunctions.add('moveServo_b');
    }

    const codeToAdd = `moveServo_b(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  moveShoulder(args) {
    const servoVar = 'servo_s';
    const MIN_ANGLE = 0;    // Define your minimum angle
    const MAX_ANGLE = 180;
    const angle = args.angle;
    const speed = args.speed;
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('shoulder', angle);
    }
    // Generate the moveServo_s function
    if (!definedFunctions.has('moveServo_s')) {
      bodycode += `
          void moveServo_s(int endAngle, int delayTime) {
            if (servo_s_pos < endAngle) {
              for (int angle = servo_s_pos; angle <= endAngle; angle++) {
                servo_s.write(angle);
                delay(delayTime);
              }
            } else {
              for (int angle = servo_s_pos; angle >= endAngle; angle--) {
                servo_s.write(angle);
                delay(delayTime);
              }
            }
            // Update the current position
            servo_s_pos = endAngle;
          }
        `;
      definedFunctions.add('moveServo_s');
    }
    const codeToAdd = `moveServo_s(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  moveElbow(args) {
    const MIN_ANGLE = 0;    // Define your minimum angle
    const MAX_ANGLE = 180;
    const angle = args.angle;
    const speed = args.speed;
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('elbow', angle);
    }
    if (!definedFunctions.has('moveServo_e')) {
      bodycode += `
        void moveServo_e(int endAngle, int delayTime) {
        if (servo_e_pos < endAngle) {
          for (int angle = servo_e_pos; angle <= endAngle; angle++) {
            servo_e.write(angle);
            delay(delayTime);
          }
        } else {
          for (int angle = servo_e_pos; angle >= endAngle; angle--) {
            servo_e.write(angle);
            delay(delayTime);
          }
        }
        servo_e_pos = endAngle;
        }
      `;
      definedFunctions.add('moveServo_e');
    }
    const codeToAdd = `moveServo_e(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  moveWrist1(args) {
    const MIN_ANGLE = 0;    // Define your minimum angle
    const MAX_ANGLE = 180;
    const angle = args.angle;
    const speed = args.speed;
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('wrist1', angle);
    }
    if (!definedFunctions.has('moveServo_w1')) {
      bodycode += `        
        void moveServo_w1(int endAngle, int delayTime) {
          if (servo_w1_pos < endAngle) {
            for (int angle = servo_w1_pos; angle <= endAngle; angle++) {
              servo_w1.write(angle);
              delay(delayTime);
            }
          } else {
            for (int angle = servo_w1_pos; angle >= endAngle; angle--) {
              servo_w1.write(angle);
              delay(delayTime);
            }
          }
          servo_w1_pos = endAngle;
        }
      `;
      definedFunctions.add('moveServo_w1');
    }
    const codeToAdd = `moveServo_w1(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  moveWrist2(args) {
    const MIN_ANGLE = 0;    // Define your minimum angle
    const MAX_ANGLE = 180;
    const angle = args.angle;
    const speed = args.speed;
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('wrist2', angle);
    }
    if (!definedFunctions.has('moveServo_w2')) {
      bodycode += `
        void moveServo_w2(int endAngle, int delayTime) {
          if (servo_w2_pos < endAngle) {
            for (int angle = servo_w2_pos; angle <= endAngle; angle++) {
              servo_w2.write(angle);
              delay(delayTime);
            }
          } else {
            for (int angle = servo_w2_pos; angle >= endAngle; angle--) {
              servo_w2.write(angle);
              delay(delayTime);
            }
          }
          servo_w2_pos = endAngle;
        }
      `;
      definedFunctions.add('moveServo_w2');
    }
    const codeToAdd = `moveServo_w2(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  moveGrip(args) {
    const MIN_ANGLE = 110;    // Define your minimum angle
    const MAX_ANGLE = 150;  // Define your maximum angle
    const angle = args.angle;
    const speed = args.speed;

    // Check if the angle is within the valid range
    if (angle < MIN_ANGLE || angle > MAX_ANGLE) {
      // console.error;
      return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
    }
    if (typeof window.moveArmJoint === 'function') {
      window.moveArmJoint('grip1', angle);
    }
    if (!definedFunctions.has('moveServo_grp')) {
      bodycode += `        
      void moveServo_grp(int endAngle, int delayTime) {
        if (servo_grp_pos < endAngle) {
          for (int angle = servo_grp_pos; angle <= endAngle; angle++) {
            servo_grp.write(angle);
            delay(delayTime);
          }
        } else {
          for (int angle = servo_grp_pos; angle >= endAngle; angle--) {
            servo_grp.write(angle);
            delay(delayTime);
          }
        }
        servo_grp_pos = endAngle;
      }
    `;
      definedFunctions.add('moveServo_grp');
    }
    const codeToAdd = `moveServo_grp(${angle}, ${speed});\n`;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }
  homePosition() {
    bodycode += `
        void home_pos() {
            ${definedServos.has('servo_b') ? 'servo_b.write(servo_b_pos);' : ''}
            ${definedServos.has('servo_s') ? 'servo_s.write(servo_s_pos);' : ''}
            ${definedServos.has('servo_e') ? 'servo_e.write(servo_e_pos);' : ''}
            ${definedServos.has('servo_w1') ? 'servo_w1.write(servo_w1_pos);' : ''}
            ${definedServos.has('servo_w2') ? 'servo_w2.write(servo_w2_pos);' : ''}
            ${definedServos.has('servo_grp') ? 'servo_grp.write(servo_grp_pos);' : ''}
        }
    `;
    const codeToAdd = `
    home_pos();
    `;

    if (localStorage.getItem('codeContext') === 'setup') {
      setupCode += codeToAdd;
    } else if (localStorage.getItem('codeContext') === 'loop') {
      loopCode += codeToAdd;
    }
    this.accumulateCode();
    this.resetCode();
  }

  setInitialPosition() {
    // Define the initial positions if not already defined
    if (!definedServos.has('initial_positions')) {
      defineCode += `
            int servo_b_pos = 90;
            int servo_s_pos = 90;
            int servo_e_pos = 90;
            int servo_w1_pos = 90;
            int servo_w2_pos = 90;
            int servo_grp_pos = 110;
          `;
      definedServos.add('initial_positions');  // Track that initial positions are defined
    }
    this.accumulateCode();
    this.resetCode();
  }
  calibrate() {
    // Check each servo and calibrate it if detected
    bodycode += `
        void calibrate_() {
            ${definedServos.has('servo_b') ? 'servo_b.write(90);' : ''}
            ${definedServos.has('servo_s') ? 'servo_s.write(90);' : ''}
            ${definedServos.has('servo_e') ? 'servo_e.write(90);' : ''}
            ${definedServos.has('servo_w1') ? 'servo_w1.write(90);' : ''}
            ${definedServos.has('servo_w2') ? 'servo_w2.write(90);' : ''}
            ${definedServos.has('servo_grp') ? 'servo_grp.write(110);' : ''}
        }
    `;
    setupCode += `
        calibrate_();
    `;
    this.accumulateCode();
    this.resetCode();
  }
}
module.exports = Scratch3RoboticArmBlocks;
