import React, { useState, useRef } from 'react';
import { Button, Container, Typography, TextField, Table, TableBody, TableCell, TableContainer,
   TableHead, TableRow, Paper, AppBar, Toolbar, IconButton, Grid, Dialog, DialogTitle, DialogContent,
    DialogActions, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import PictoBloxMLEnvironment from './components/PictoBloxMLEnvironment.js';
import AudioClassification from './components/AudioClassifier.js';
import PoseClassifier from './components/PoseClassifier.js';
import ObjectDetection from './components/ObjectDetection.js';
import HandDetection from './components/HandPose.js';
import image1 from './images/image1.png';
import poseClassifier from './images/image2.png';
import Object from './images/image3.png';
import Hand from './images/4.png';
import Audio from './images/image4.png';

// Custom theme with updated color
const theme = createTheme({
  palette: {
    primary: {
      main: '#003AD4', // Updated color
    },
    secondary: {
      main: '#003AD4', // Lighter teal for secondary elements
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

function Dashboard() {
  const [projects] = useState([
    { name: ' ', type: '', classes: '', lastUpdated: '', status: '' },
     ]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [openAbout, setOpenAbout] = useState(false);
  const theme = useTheme();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };
  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleOpenProject = () => {
    fileInputRef.current.click();
  };

  const handleOpenAbout = () => {
    setOpenAbout(true);
  };

  // Function to handle "About" modal close
  const handleCloseAbout = () => {
    setOpenAbout(false);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Selected file:', file.name);
      // Add your file processing logic here
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: theme.palette.background.default }}>
    <AppBar position="static" color="primary">
      <Toolbar>
         <IconButton
              onClick={handleBackClick}
              className="text-white hover:bg-blue-700"
            >
              <ArrowBackIcon />
            </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1, marginLeft: '10px', fontWeight: 'bold' }}>
          StemVerse Machine Learning Environment
        </Typography>
        <Typography variant="subtitle1" style={{ marginRight: '20px', fontStyle: 'italic' }}>
          
        </Typography>
        {/* About Button */}
        <Button color="inherit" onClick={handleOpenAbout}>About</Button>
        <IconButton color="inherit">
          {/* <AccountCircleIcon /> */}
        </IconButton>
      </Toolbar>
    </AppBar>
    <Dialog open={openAbout} onClose={handleCloseAbout} fullWidth maxWidth="sm">
        <DialogTitle>About StemVerse Machine Learning Platform</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            StemVerse is an engaging and interactive machine learning platform designed for young learners 
            aged 9 to 16. The platform introduces users to foundational data science concepts, empowering 
            them to understand and apply machine learning techniques in an easy-to-use, fun, and hands-on environment.
          </Typography>
          <Typography variant="body1" gutterBottom>
            With StemVerse, students will explore various topics including image classification, data 
            analysis, predictive modeling, and more. The platform features age-appropriate challenges 
            and activities to build practical skills, encouraging curiosity and innovation in fields like 
            artificial intelligence, computer vision, and data science.
          </Typography>
          <Typography variant="body1" gutterBottom>
            StemVerse provides intuitive tools for learning, such as easy-to-understand visualizations and 
            interactive experiments. The platform's main goal is to simplify complex concepts and inspire 
            the next generation of data scientists and AI enthusiasts.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAbout} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
  
    <Container style={{ flexGrow: 1, padding: '30px', backgroundColor: theme.palette.background.paper, borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px' }}>
        <Typography variant="h5" style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
          My Projects
        </Typography>
        <div>
          <TextField
            placeholder="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: <SearchIcon color="action" />,
            }}
            style={{ marginRight: '10px', backgroundColor: 'white', borderRadius: '5px' }}
          />
          <Button variant="contained" color="secondary" style={{ marginRight: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)' }} onClick={() => navigate('/create-project')}>
            Create New Project
          </Button>
          <Button variant="contained" color="secondary" style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)' }} onClick={handleOpenProject}>
            Open Project
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileSelect}
          />
        </div>
      </div>
  
      <TableContainer component={Paper} style={{ borderRadius: '10px', overflow: 'hidden' }}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
              <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Project Details</TableCell>
              <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Type</TableCell>
              <TableCell style={{ color: 'white', fontWeight: 'bold' }}>No. of Classes</TableCell>
              <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Last Updated</TableCell>
              <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
              <TableCell style={{ color: 'white' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProjects.map((project, index) => (
              <TableRow key={index} hover style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}>
                <TableCell>{project.name}</TableCell>
                <TableCell>
                  <span style={{
                    backgroundColor: project.type === 'Image Classifier' ? '#e3f2fd' : '#fce4ec',
                    color: project.type === 'Image Classifier' ? '#1565c0' : '#c2185b',
                    padding: '5px 10px',
                    borderRadius: '15px',
                    fontSize: '0.9rem',
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                  }}>
                    {project.type}
                  </span>
                </TableCell>
                <TableCell>{project.classes}</TableCell>
                <TableCell>{project.lastUpdated}</TableCell>
                <TableCell>{project.status}</TableCell>
                <TableCell>
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  </div>
  
  );
}
function CreateProject() {
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [comingSoonOpen, setComingSoonOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null); // New state to track active component
  const navigate = useNavigate();

  const projectTypes = [
    { name: 'Image Classifier', image: image1, component: <PictoBloxMLEnvironment /> },
    { name: 'Pose Classifier', image: poseClassifier, component: <PoseClassifier /> },
    { name: 'Object Detection', image: Object, component: <ObjectDetection /> },
    { name: 'Hand Pose Classifier', image: Hand, component: <HandDetection /> },
    { name: 'Audio Classifier', image: Audio, component: <AudioClassification /> },
  ];

  const handleSelectProjectType = (type) => {
    console.log('Selected project type:', type);
    setSelectedType(type); // Set selected type when a project type is clicked
  };

  const handleCreateProject = () => {
    console.log('Creating project:', { projectName, projectDescription, selectedType });
    
    const foundType = projectTypes.find(pt => pt.name === selectedType);
    if (foundType) {
      setActiveComponent(foundType.component); // Show the selected component
    } else {
      setActiveComponent(<PictoBloxMLEnvironment />); // Default component
    }
  };

  const handleComingSoonClose = () => {
    setComingSoonOpen(false);
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: theme.palette.background.default }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <IconButton onClick={handleBackClick} className="text-white hover:bg-blue-700">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1, marginLeft: '10px', fontWeight: 'bold' }}>
            StemVerse Machine Learning Environment
          </Typography>
          <IconButton color="inherit">
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container style={{ flexGrow: 1, padding: '30px', backgroundColor: theme.palette.background.paper, borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        {activeComponent ? (
          activeComponent // Render the selected component
        ) : (
          <>
            <Typography variant="h5" style={{ marginBottom: '20px', color: theme.palette.primary.main, fontWeight: 'bold' }}>
              Create New Project
            </Typography>

            <TextField
              label="Project Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
              style={{ marginBottom: '20px' }}
            />

            <TextField
              label="Project Description"
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
              style={{ marginBottom: '20px' }}
            />

            <Grid container spacing={3} style={{ marginTop: '30px' }}>
              {projectTypes.map((type) => (
                <Grid item xs={12} sm={6} md={4} key={type.name}>
                  <Paper
                    elevation={selectedType === type.name ? 6 : 3}
                    style={{
                      padding: '15px',
                      cursor: 'pointer',
                      backgroundColor: selectedType === type.name ? theme.palette.primary.light : 'white',
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      transform: selectedType === type.name ? 'scale(1.05)' : 'scale(1)',
                      boxShadow: selectedType === type.name ? '0px 6px 12px rgba(0, 0, 0, 0.2)' : '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                    onClick={() => handleSelectProjectType(type.name)} // Updated to select the project type
                  >
                    <img
                      src={type.image}
                      alt={type.name}
                      style={{ width: '250px', height: '140px', borderRadius: '8px' }} // Updated size
                    />
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ marginTop: '12px', color: theme.palette.primary.main, fontWeight: 'bold' }}
                    >
                      {type.name}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>

            <div style={{ marginTop: '40px', textAlign: 'center' }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleCreateProject} // Create project when clicked
                style={{ padding: '10px 30px', fontWeight: 'bold', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}
                disabled={!selectedType} // Disable if no type is selected
              >
                Create Project
              </Button>
            </div>
          </>
        )}
      </Container>

      {/* Coming Soon Dialog */}
      <Dialog open={comingSoonOpen} onClose={handleComingSoonClose}>
        <DialogTitle>Coming Soon</DialogTitle>
        <DialogContent>
          <Typography>Object Detection, Hand Pose Classifier, Audio Classifier are coming soon!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleComingSoonClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<CreateProject />} />
          {/* Remove the other routes since we're handling component rendering in CreateProject */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;